<template>
  <div>
    <h4>REQUERIMIENTOS</h4>
    <app-table-registers
      ref="tr"
      :getList="getList"
      @btnNew="$refs.mForm.show()"
    >
      <template slot="table">
        <thead>
          <tr>
            <th>ID</th>
            <th></th>
            <th></th>
            <th>ALMACEN SOLICITANTE</th>
            <th>ALMACEN PROVEEDOR</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="l in list.data" :key="l.id">
            <th>{{ l.id }}</th>
            <td>{{ l.created_at | date }} </td>
            <th>
              <span
                class="badge badge-warning mr-1"
                v-for="(l1, i1) in l.categories"
                :key="i1"
                >{{ l1.categorie_name }}
              </span>
            </th>
            <td>{{ l.store_name }}</td>
            <td>{{ l.target_store_name }}</td>
            <td>
              <router-link
                :to="'/logistic/requirements/' + l.id"
                class="btn btn-sm btn-light"
              >
                <i class="fa fa-edit"></i>
              </router-link>
            </td>
          </tr>
        </tbody>
      </template>
    </app-table-registers>

    <app-modal-basic ref="mForm">
      <h4>NUEVO REQUERIMIENTO</h4>
      <FormCreate
        @submitted="$router.push('/logistic/requirements/' + $event)"
      ></FormCreate>
    </app-modal-basic>
  </div>
</template>

<script>
import FormCreate from "./FormCreate";
import { LogisticService } from "../service";

export default {
  components: {
    FormCreate
  },
  data: () => ({
    list: {}
  }),
  methods: {
    getList(params) {
      return new Promise(rsv => {
        LogisticService.getRequirements(params).then(res => {
          this.list = res;
          rsv(res);
        });
      });
    }
  }
};
</script>

<style>
</style>
